import React from "react"

import Layout from "src/layout"
import Column from "src/layout/column"

const NotFoundPage: GatsbyPage = () => (
  <Layout title="404" noindex>
    <Column $center>
      <h1>Page introuvable</h1>
      <p>Cette page n’existe pas</p>
    </Column>
  </Layout>
)

export default NotFoundPage
